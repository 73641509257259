<template>
	<div>
		<div class="qualificationsHonors" v-if="windowWidth>=1200">
			<div class="qh_title_cn">{{$t('aboutUs.qualification.title')}}</div>
			<!-- <div class="qh_title_en">QUALIFICATION</div> -->

			<el-carousel height="500px">
				<el-carousel-item v-for="(item,index) in list" :key="index">
					<div class="qh_carousel">
						<div class="qh_ca_div" v-for="(item2,index2) in item.data" :key="index2">
							<el-image class="qh_ca_div_image" :src="item2.image" :fit="'contain'"></el-image>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>

		<div class="qualificationsHonors" v-if="windowWidth<=1199">
			<div class="qh_title_cn">{{$t('aboutUs.qualification.title')}}</div>
			<!-- <div class="qh_title_en">QUALIFICATION</div> -->

			<el-carousel height="500px">
				<el-carousel-item v-for="(item,index) in list2" :key="index">
					<div class="qh_carousel">
						<div class="qh_ca_div" v-for="(item2,index2) in item.data" :key="index2">
							<el-image class="qh_ca_div_image" :src="item2.image" :fit="'contain'"></el-image>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				list: [{
					data: [{
						id: 1,
						image: require('@/assets/img/esc.png')
					}, {
						id: 2,
						image: require('@/assets/img/henlv1.png')
					}, {
						id: 3,
						image: require('@/assets/img/nkt.png')
					}, {
						id: 4,
						image: require('@/assets/img/qlmos.png')
					}, {
						id: 5,
						image: require('@/assets/img/ISO.png')
					}]
				}],
				list2: [{
						data: [{
							id: 1,
							image: require('@/assets/img/esc.png')
						}]
					},
					{
						data: [{
							id: 2,
							image: require('@/assets/img/henlv1.png')
						}]
					},
					{
						data: [{
							id: 3,
							image: require('@/assets/img/nkt.png')
						}]
					},
					{
						data: [{
							id: 4,
							image: require('@/assets/img/qlmos.png')
						}]
					},
					{
						data: [{
							id: 5,
							image: require('@/assets/img/ISO.png')
						}]
					},
				]
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}

	}
</script>

<style lang="less" scoped>
	@media only screen and (min-width:1920px) {
		.qualificationsHonors {
			animation-fill-mode: both;
			background-size: cover;
			background-position: center center;
			padding: 60px 200px;

			.qh_title_cn {
				text-align: center;
				font-size: 32px;
				font-weight: bold;
				color: #fff;
				color: #333;
			}

			.qh_title_en {
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				line-height: 1;
				color: #333;
			}

			.qh_carousel {
				width: 100%;
				height: 400px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 90px;

				.qh_ca_div {
					width: calc((100% - 12vw) / 5);
					height: 100%;
					display: flex;
					margin: 16px;
					position: relative;
					padding: 20px;
					border: 1px solid #333;

					.qh_ca_div_image {
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
				}
			}
		}
	}

	@media only screen and (max-width:1920px) {
		.qualificationsHonors {
			animation-fill-mode: both;
			background-size: cover;
			background-position: center center;
			padding: 60px 200px;

			.qh_title_cn {
				text-align: center;
				font-size: 32px;
				font-weight: bold;
				color: #fff;
				color: #333;
			}

			.qh_title_en {
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				line-height: 1;
				color: #333;
			}

			.qh_carousel {
				width: 100%;
				height: 400px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 90px;

				.qh_ca_div {
					width: calc((100% - 12vw) / 5);
					height: 100%;
					display: flex;
					margin: 16px;
					position: relative;
					padding: 20px;
					border: 1px solid #333;

					.qh_ca_div_image {
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
				}
			}
		}
	}

	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.qualificationsHonors {
			animation-fill-mode: both;
			background-size: cover;
			background-position: center center;
			padding: 60px 100px;

			.qh_title_cn {
				text-align: center;
				font-size: 32px;
				font-weight: bold;
				color: #fff;
				color: #333;
			}

			.qh_title_en {
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				line-height: 1;
				color: #333;
			}

			.qh_carousel {
				width: 100%;
				height: 400px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 90px;

				.qh_ca_div {
					width: calc((100% - 12vw) / 5);
					display: flex;
					margin: 16px;
					position: relative;
					padding: 20px;
					border: 1px solid #333;

					.qh_ca_div_image {
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
				}
			}
		}
	}

	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.qualificationsHonors {
			animation-fill-mode: both;
			background-size: cover;
			background-position: center center;
			padding: 60px 60px;

			.qh_title_cn {
				text-align: center;
				font-size: 32px;
				font-weight: bold;
				color: #fff;
				color: #333;
			}

			.qh_title_en {
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				line-height: 1;
				color: #333;
			}

			.qh_carousel {
				width: 100%;
				height: 400px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 90px;

				.qh_ca_div {
					width: calc((100% - 12vw) / 5);
					display: flex;
					margin: 16px;
					position: relative;
					padding: 20px;
					border: 1px solid #333;

					.qh_ca_div_image {
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
				}
			}
		}
	}

	@media only screen and (max-width:1199px) and (min-width:768px) {

		.qualificationsHonors {
			animation-fill-mode: both;
			background-size: cover;
			background-position: center center;
			padding: 60px 60px;

			.qh_title_cn {
				text-align: center;
				font-size: 32px;
				font-weight: bold;
				color: #fff;
				color: #333;
			}

			.qh_title_en {
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				line-height: 1;
				color: #333;
			}

			.qh_carousel {
				width: 100%;
				height: 400px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 90px;

				.qh_ca_div {
					width: calc((100% - 12vw) / 2);
					display: flex;
					margin: 16px;
					position: relative;
					padding: 20px;
					border: 1px solid #333;

					.qh_ca_div_image {
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
				}
			}
		}

	}

	@media only screen and (max-width:767px) {
		
		
			.qualificationsHonors {
				animation-fill-mode: both;
				background-size: cover;
				background-position: center center;
				padding: 20px;
		
				.qh_title_cn {
					text-align: center;
					font-size: 32px;
					font-weight: bold;
					color: #fff;
					color: #333;
				}
		
				.qh_title_en {
					text-align: center;
					font-size: 24px;
					font-weight: bold;
					color: #fff;
					line-height: 1;
					color: #333;
				}
		
				.qh_carousel {
					width: 100%;
					height: 350px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 90px;
		
					.qh_ca_div {
						width: calc((100% - 12vw) / 1);
						display: flex;
						margin: 16px;
						position: relative;
						padding: 20px;
						border: 1px solid #333;
		
						.qh_ca_div_image {
							text-align: center;
							position: relative;
							width: 100%;
							transition: all 0.5s;
						}
					}
				}
			}
		
		
	}
</style>
