<template>
	<div>
		<div id="organizationalStructure"></div>
		<div style="text-align: center;padding: 40px;">
			<div
				style="display: inline-block;font-size: 32px;line-height: 1.4;text-transform: uppercase;color: #000;font-weight: bold;">
				组织架构</div>
			<div style="font-size: 24px;color: #333;line-height: 50px;">ORGANIZATION</div>
		</div>

		<div style="padding: 20px 200px;display: flex;justify-content: center;align-items: center;">
			<el-image :src="'http://www.unibetter-ic.com/img/n_jg.png'" :fit="'cover'"></el-image>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>

</style>
