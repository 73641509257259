<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="about" style="">
				<div style="">
					<div class="ab_title">{{$t('aboutUs.aboutUs.title1')}}</div>
					<div class="ab_title2">{{$t('homeAbout.content1')}}</div>
				</div>
				
				<div style="padding-top: 20px;">
					<div class="ab_content">
						{{$t('homeAbout.content2')}}
					</div>
					<div class="ab_content">
						{{$t('homeAbout.content3')}}
					</div>
					<div class="ab_content" v-if="$i18n.locale=='en'">
						{{$t('homeAbout.content4')}}
					</div>
					<div class="ab_content" v-if="$i18n.locale=='en'">
						{{$t('homeAbout.content5')}}
					</div>
				</div>
			</div>
			
			<div>
				<!-- 公司行情信息 -->
				<div class="companyQuotations">
					<div class="cq_cq" style="">
						<div class="cq_div">
							<div class="cq_div_marketQuotations">
								<div class="cq_div_mq_div">
									<div>
										<div style="display: flex;">
											<div class="text1">2014</div>
											<div class="text2">{{$t('aboutUs.marketQuotations.text1')}}</div>
										</div>
										<div style="color: #666;height: 60px;padding-right: 10px;">{{$t('aboutUs.marketQuotations.content1')}}</div>
									</div>
								</div>
									
								<div class="cq_div_mq_div">
									<div>
										<div style="display: flex;">
											<div class="text1">10+</div>
											<div class="text2">{{$t('aboutUs.marketQuotations.text1')}}</div>
										</div>
										<div style="color: #666;height: 60px;padding-right: 10px;">{{$t('aboutUs.marketQuotations.content2')}}</div>
									</div>
								</div>
									
								<div class="cq_div_mq_div">
									<div>
										<div style="display: flex;">
											<div class="text1">26</div>
											<div class="text2">{{$t('aboutUs.marketQuotations.text2')}}</div>
										</div>
										<div style="color: #666;height: 60px;padding-right: 10px;">{{$t('aboutUs.marketQuotations.content3')}}</div>
									</div>
								</div>
									
								<div class="cq_div_mq_div">
									<div>
										<div style="display: flex;">
											<div class="text1">100+</div>
											<div class="text2">{{$t('aboutUs.marketQuotations.text3')}}</div>
										</div>
										<div style="color: #666;height: 60px;padding-right: 10px;">{{$t('aboutUs.marketQuotations.content4')}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<el-image class="cq_image" :src="'http://img601.yun300.cn/repository/image/ffd98772-7609-4659-854b-beea5b2f5f5d.jpg?tenantId=252768&viewType=1&k=1678089441000'" :fit="'cover'"></el-image>
				</div>
				
				<div class="ce_ce" style="">
					<div class="companyEnvironment">
						<div class="ce_div">
							<el-image class="ce_div_image" :src="require('@/assets/img/companyProfile.png')" fit="cover"></el-image>
						</div>
						
						<div class="ce_div2">
							<el-image class="ce_div_image2" :src="require('@/assets/img/adout1.jpg')" :preview-src-list="srcList1" fit="cover"></el-image>
						</div>
						<div class="ce_div2">
							<el-image class="ce_div_image2" :src="require('@/assets/img/adout2.jpg')" :preview-src-list="srcList2" fit="cover"></el-image>
						</div>
						<div class="ce_div2">
							<el-image class="ce_div_image2" :src="require('@/assets/img/adout3.jpg')" :preview-src-list="srcList3" fit="cover"></el-image>
						</div>
						<div class="ce_div2">
							<el-image class="ce_div_image2" :src="require('@/assets/img/adout4.jpg')" :preview-src-list="srcList3" fit="cover"></el-image>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="windowWidth<=1199">
			<div class="about" style="">
				<div style="">
					<div class="ab_title">{{$t('aboutUs.aboutUs.title1')}}</div>
					<div class="ab_title2">{{$t('aboutUs.aboutUs.title2')}}</div>
				</div>
				
				<div style="padding-top: 20px;">
					<div class="ab_content">
						{{$t('homeAbout.content1')}}
					</div>
					<div class="ab_content">
						{{$t('homeAbout.content2')}}
					</div>
				</div>
			</div>
			
			<div>
				<!-- 公司行情信息 -->
				<div class="companyQuotations">
					<div class="cq_cq" style="">
						<div class="cq_div">
							<div class="cq_div_marketQuotations2">
								<div style="display: flex;">
									<div class="cq_div_mq_div2">
										<div>
											<div style="display: flex;">
												<div class="text1">2014</div>
												<div class="text2">{{$t('aboutUs.marketQuotations.text1')}}</div>
											</div>
											<div class="text3">{{$t('aboutUs.marketQuotations.content1')}}</div>
										</div>
									</div>
										
									<div class="cq_div_mq_div2">
										<div>
											<div style="display: flex;">
												<div class="text1">10+</div>
												<div class="text2">{{$t('aboutUs.marketQuotations.text1')}}</div>
											</div>
											<div class="text3">{{$t('aboutUs.marketQuotations.content2')}}</div>
										</div>
									</div>
								</div>
								
								<div style="display: flex;">
									<div class="cq_div_mq_div2">
										<div>
											<div style="display: flex;">
												<div class="text1">26</div>
												<div class="text2">{{$t('aboutUs.marketQuotations.text2')}}</div>
											</div>
											<div class="text3">{{$t('aboutUs.marketQuotations.content3')}}</div>
										</div>
									</div>
										
									<div class="cq_div_mq_div2">
										<div>
											<div style="display: flex;">
												<div class="text1">100+</div>
												<div class="text2">{{$t('aboutUs.marketQuotations.text3')}}</div>
											</div>
											<div class="text3">{{$t('aboutUs.marketQuotations.content4')}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<el-image class="cq_image" :src="'http://img601.yun300.cn/repository/image/ffd98772-7609-4659-854b-beea5b2f5f5d.jpg?tenantId=252768&viewType=1&k=1678089441000'" :fit="'cover'"></el-image>
				</div>
				
				<div class="ce_ce" style="">
					<div class="companyEnvironment2">
						<div style="display: flex;">
							<div class="ce_div2">
								<el-image class="ce_div_image2" :src="require('@/assets/img/adout4.jpg')" fit="cover"></el-image>
							</div>
							<div class="ce_div2">
								<el-image class="ce_div_image2" :src="require('@/assets/img/adout1.jpg')" :preview-src-list="srcList1" fit="cover"></el-image>
							</div>
						</div>
						<div style="display: flex;">
							<div class="ce_div2">
								<el-image class="ce_div_image2" :src="require('@/assets/img/adout2.jpg')" :preview-src-list="srcList2" fit="cover"></el-image>
							</div>
							<div class="ce_div2">
								<el-image class="ce_div_image2" :src="require('@/assets/img/adout3.jpg')" :preview-src-list="srcList3" fit="cover"></el-image>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				srcList1:[require('@/assets/img/adout1.jpg')],
				srcList2:[require('@/assets/img/adout2.jpg')],
				srcList3:[require('@/assets/img/adout3.jpg')],
				srcList4:[require('@/assets/img/adout4.jpg')],
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.about{
			padding: 60px 200px;
			
			.ab_title{
				display: inline-block;
				font-size: 32px;
				line-height: 1.4;
				text-transform: uppercase;
				color: #000;
				font-weight: bold;
			}
			
			.ab_title2{
				font-size: 24px;
				color: #333;
				line-height: 50px;
			}
			
			.ab_content{
				color: #333;
				font-size: 16px;
				line-height: 2;
				padding: 8px 0;
			}
		}
		
		//公司行情
		.companyQuotations{
			position: relative;
			width: 100%;
			height: 24vh;
			
			.cq_cq{
				position: relative;
				margin: 0 200px;
				
				.cq_div{
					position: absolute;
					// bottom: 20%;
					margin-top: 230px;
					left: 50%;
					width: 100%;
					transform: translate(-50%,-50%);
					z-index: 9;
					padding: 20px;
					background: #fff;
					box-shadow: 0 0 8px 0 #ccc;
					
					.cq_div_marketQuotations{
						width: 100%;
						position: relative;
						display: flex;
						align-items: center;
						padding: 40px 0;
						
						.cq_div_mq_div{
							width: 25%;
							justify-content: center;
							align-items: center;
							display: flex;
							
							.text1{
								font-size: 52px;
								font-weight: bold;
								font-family: BigJohn;
								background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
							}
							
							.text2{
								display: flex;
								align-items: flex-end;
								padding-bottom: 10px;
								padding-left: 5px;
								font-weight: bold;
								font-size: 16px;
								color: #666;
							}
						}
					}
				}
			}
			
			.cq_image{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		
		.ce_ce{
			padding: 120px 200px 80px 200px;
		}
		
		//公司简介相册
		.companyEnvironment{
			padding-top: 40px;
			width: 100%;
			display: flex;
			
			.ce_div{
				width: 15%;
				
				.ce_div_image{
					width: 100%;
					height: 180px;
				}
			}
			
			.ce_div2{
				width: 21.25%;
				
				.ce_div_image2{
					width: 100%;
					height: 180px;
					padding-left: 20px;
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.about{
			padding: 60px 200px;
			
			.ab_title{
				display: inline-block;
				font-size: 32px;
				line-height: 1.4;
				text-transform: uppercase;
				color: #000;
				font-weight: bold;
			}
			
			.ab_title2{
				font-size: 24px;
				color: #333;
				line-height: 50px;
			}
			
			.ab_content{
				color: #333;
				font-size: 16px;
				line-height: 2;
				padding: 8px 0;
			}
		}
		
		//公司行情
		.companyQuotations{
			position: relative;
			width: 100%;
			height: 24vh;
			
			.cq_cq{
				position: relative;
				margin: 0 200px;
				
				.cq_div{
					position: absolute;
					// bottom: 20%;
					margin-top: 230px;
					left: 50%;
					width: 100%;
					transform: translate(-50%,-50%);
					z-index: 9;
					padding: 20px;
					background: #fff;
					box-shadow: 0 0 8px 0 #ccc;
					
					.cq_div_marketQuotations{
						width: 100%;
						position: relative;
						display: flex;
						align-items: center;
						padding: 40px 0;
						
						.cq_div_mq_div{
							width: 25%;
							justify-content: center;
							align-items: center;
							display: flex;
							
							.text1{
								font-size: 52px;
								font-weight: bold;
								font-family: BigJohn;
								background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
							}
							
							.text2{
								display: flex;
								align-items: flex-end;
								padding-bottom: 10px;
								padding-left: 5px;
								font-weight: bold;
								font-size: 16px;
								color: #666;
							}
						}
					}
				}
			}
			
			.cq_image{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		
		.ce_ce{
			padding: 120px 200px 80px 200px;
		}
		
		//公司简介相册
		.companyEnvironment{
			padding-top: 40px;
			width: 100%;
			display: flex;
			
			.ce_div{
				width: 15%;
				
				.ce_div_image{
					width: 100%;
					height: 180px;
				}
			}
			
			.ce_div2{
				width: 21.25%;
				
				.ce_div_image2{
					width: 100%;
					height: 180px;
					padding-left: 20px;
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.about{
			padding: 60px 100px;
			
			.ab_title{
				display: inline-block;
				font-size: 32px;
				line-height: 1.4;
				text-transform: uppercase;
				color: #000;
				font-weight: bold;
			}
			
			.ab_title2{
				font-size: 24px;
				color: #333;
				line-height: 50px;
			}
			
			.ab_content{
				color: #333;
				font-size: 16px;
				line-height: 2;
				padding: 8px 0;
			}
		}
		
		//公司行情
		.companyQuotations{
			position: relative;
			width: 100%;
			height: 24vh;
			
			.cq_cq{
				position: relative;
				margin: 0 100px;
				
				.cq_div{
					position: absolute;
					// bottom: 20%;
					margin-top: 230px;
					left: 50%;
					width: 100%;
					transform: translate(-50%,-50%);
					z-index: 9;
					padding: 20px;
					background: #fff;
					box-shadow: 0 0 8px 0 #ccc;
					
					.cq_div_marketQuotations{
						width: 100%;
						position: relative;
						display: flex;
						align-items: center;
						padding: 40px 0;
						
						.cq_div_mq_div{
							width: 25%;
							justify-content: center;
							align-items: center;
							display: flex;
							
							.text1{
								font-size: 52px;
								font-weight: bold;
								font-family: BigJohn;
								background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
							}
							
							.text2{
								display: flex;
								align-items: flex-end;
								padding-bottom: 10px;
								padding-left: 5px;
								font-weight: bold;
								font-size: 16px;
								color: #666;
							}
						}
					}
				}
			}
			
			.cq_image{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		
		.ce_ce{
			padding: 120px 100px 80px 100px;
		}
		
		//公司简介相册
		.companyEnvironment{
			padding-top: 40px;
			width: 100%;
			display: flex;
			
			.ce_div{
				width: 15%;
				
				.ce_div_image{
					width: 100%;
					height: 180px;
				}
			}
			
			.ce_div2{
				width: 21.25%;
				
				.ce_div_image2{
					width: 100%;
					height: 180px;
					padding-left: 20px;
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.about{
			padding: 60px 60px;
			
			.ab_title{
				display: inline-block;
				font-size: 32px;
				line-height: 1.4;
				text-transform: uppercase;
				color: #000;
				font-weight: bold;
			}
			
			.ab_title2{
				font-size: 24px;
				color: #333;
				line-height: 50px;
			}
			
			.ab_content{
				color: #333;
				font-size: 16px;
				line-height: 2;
				padding: 8px 0;
			}
		}
		
		//公司行情
		.companyQuotations{
			position: relative;
			width: 100%;
			height: 24vh;
			
			.cq_cq{
				position: relative;
				margin: 0 60px;
				
				.cq_div{
					position: absolute;
					// bottom: 20%;
					margin-top: 230px;
					left: 50%;
					width: 100%;
					transform: translate(-50%,-50%);
					z-index: 9;
					padding: 20px;
					background: #fff;
					box-shadow: 0 0 8px 0 #ccc;
					
					.cq_div_marketQuotations{
						width: 100%;
						position: relative;
						display: flex;
						align-items: center;
						padding: 40px 0;
						
						.cq_div_mq_div{
							width: 25%;
							justify-content: center;
							align-items: center;
							display: flex;
							
							.text1{
								font-size: 52px;
								font-weight: bold;
								font-family: BigJohn;
								background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
							}
							
							.text2{
								display: flex;
								align-items: flex-end;
								padding-bottom: 10px;
								padding-left: 5px;
								font-weight: bold;
								font-size: 16px;
								color: #666;
							}
						}
					}
				}
			}
			
			.cq_image{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		
		.ce_ce{
			padding: 120px 60px 80px 60px;
		}
		
		//公司简介相册
		.companyEnvironment{
			padding-top: 40px;
			width: 100%;
			display: flex;
			
			.ce_div{
				width: 15%;
				
				.ce_div_image{
					width: 100%;
					height: 180px;
				}
			}
			
			.ce_div2{
				width: 21.25%;
				
				.ce_div_image2{
					width: 100%;
					height: 180px;
					padding-left: 20px;
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		
			.about{
				padding: 60px 60px;
				
				.ab_title{
					display: inline-block;
					font-size: 26px;
					line-height: 1.4;
					text-transform: uppercase;
					color: #000;
					font-weight: bold;
				}
				
				.ab_title2{
					font-size: 18px;
					color: #333;
					line-height: 30px;
				}
				
				.ab_content{
					color: #333;
					font-size: 14px;
					line-height: 2;
					padding: 8px 0;
				}
			}
			
			//公司行情
			.companyQuotations{
				position: relative;
				width: 100%;
				height: 24vh;
				
				.cq_cq{
					position: relative;
					margin: 0 60px;
					
					.cq_div{
						position: absolute;
						// bottom: 20%;
						margin-top: 230px;
						left: 50%;
						width: 100%;
						transform: translate(-50%,-50%);
						z-index: 9;
						padding: 20px;
						background: #fff;
						box-shadow: 0 0 8px 0 #ccc;
						display: flex;
						align-items: center;
						
						.cq_div_marketQuotations2{
							width: 100%;
							position: relative;
							// padding: 40px 0;
							
							.cq_div_mq_div2{
								width: 50%;
								// justify-content: center;
								// align-items: center;
								// display: flex;
								
								.text1{
									font-size: 36px;
									font-weight: bold;
									font-family: BigJohn;
									background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
									-webkit-background-clip: text;
									-webkit-text-fill-color: transparent;
								}
								
								.text2{
									display: flex;
									align-items: flex-end;
									padding-bottom: 10px;
									padding-left: 5px;
									font-weight: bold;
									font-size: 12px;
									color: #666;
								}
								
								.text3{
									color: #666;
									height: 60px;
									padding-right: 10px;
									font-size: 10px;
								}
							}
						}
					}
				}
				
				.cq_image{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
			
			.ce_ce{
				padding: 120px 60px 80px 60px;
			}
			
			//公司简介相册
			.companyEnvironment2{
				padding-top: 60px;
				width: 100%;
				
				.ce_div{
					width: 50%;
					
					.ce_div_image{
						width: 100%;
						height: 220px;
					}
				}
				
				.ce_div2{
					width: 50%;
					
					.ce_div_image2{
						width: 100%;
						height: 240px;
					}
				}
			}
		
	}
	
	@media only screen and (max-width:767px) {
		
			
				.about{
					padding: 20px;
					
					.ab_title{
						display: inline-block;
						font-size: 20px;
						line-height: 1.4;
						text-transform: uppercase;
						color: #000;
						font-weight: bold;
					}
					
					.ab_title2{
						font-size: 16px;
						color: #333;
						line-height: 30px;
						padding-top: 10px;
					}
					
					.ab_content{
						color: #333;
						font-size: 10px;
						line-height: 2;
						padding: 8px 0;
					}
				}
				
				//公司行情
				.companyQuotations{
					position: relative;
					width: 100%;
					height: 24vh;
					
					.cq_cq{
						position: relative;
						margin: 0 20px;
						
						.cq_div{
							position: absolute;
							// bottom: 20%;
							margin-top: 180px;
							left: 50%;
							width: 100%;
							transform: translate(-50%,-50%);
							z-index: 9;
							padding: 20px;
							background: #fff;
							box-shadow: 0 0 8px 0 #ccc;
							display: flex;
							align-items: center;
							
							.cq_div_marketQuotations2{
								width: 100%;
								position: relative;
								// padding: 40px 0;
								
								.cq_div_mq_div2{
									width: 50%;
									// justify-content: center;
									// align-items: center;
									// display: flex;
									
									.text1{
										font-size: 36px;
										font-weight: bold;
										font-family: BigJohn;
										background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
										-webkit-background-clip: text;
										-webkit-text-fill-color: transparent;
									}
									
									.text2{
										display: flex;
										align-items: flex-end;
										padding-bottom: 10px;
										padding-left: 5px;
										font-weight: bold;
										font-size: 10px;
										color: #666;
									}
									
									.text3{
										color: #666;
										height: 60px;
										padding-right: 10px;
										font-size: 8px;
									}
								}
							}
						}
					}
					
					.cq_image{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
				
				.ce_ce{
					padding: 120px 20px 80px 20px;
				}
				
				//公司简介相册
				.companyEnvironment2{
					padding-top: 60px;
					width: 100%;
					
					.ce_div{
						width: 50%;
						
						.ce_div_image{
							width: 100%;
							height: 220px;
						}
					}
					
					.ce_div2{
						width: 50%;
						
						.ce_div_image2{
							width: 100%;
							height: 240px;
						}
					}
				}
			
		
	}
</style>