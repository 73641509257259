<template>
	<div>
		<div class="developmentHistory" :style="'background-image:' + `url('http://static601.yun300.cn/img/culturebg.jpg?tenantId=252768&viewType=1&v=1678089441000')`">
			<div class="dh_title_cn">{{$t('aboutUs.developmentHistory.title')}}</div>
			<!-- <div class="dh_title_en">HISTORY</div> -->
		
			<div class="thumb-example">
				<!-- swiper1 -->
				<swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
					<swiper-slide class="slide" v-for="(item,index) in $t('aboutUs.developmentHistory.list')" :key="index">
						<div class="sl_textTime">{{item.time}}</div>
						<div class="sl_div">
							<div class="sl_div_div">
								<div class="sl_div_div_circle"></div>
								<div class="sl_div_div_line"></div>
							</div>
						</div>
					</swiper-slide>
				</swiper>
				<!-- swiper2 -->
				<swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
					<swiper-slide class="slide2" :style="'background-image:url(' +  item.image + ');'" v-for="(item,index) in $t('aboutUs.developmentHistory.list')" :key="index">
						<div class="sl2_div">
							<div class="sl2_div_div">
								<div class="sl2_div_div_time">{{item.time}}</div>
								<div class="sl2_div_div_content">{{item.content}}</div>
							</div>
							<div class="sl2_div_bg"></div>
						</div>
					</swiper-slide>
					<div class="swiper-button-next swiper-button-white" slot="button-next"></div>
					<div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
				</swiper>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	require("swiper/dist/css/swiper.css");
	export default {
		components: {
			swiper,
			swiperSlide,
		},
		data() {
			return {
				swiperOptionTop: {
					loop: true,
					loopedSlides: 5, // looped slides should be the same
					spaceBetween: 10,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				},
				swiperOptionThumbs: {
					loop: true,
					loopedSlides: 5, // looped slides should be the same
					spaceBetween: 0,
					centeredSlides: true,
					slidesPerView: 'auto',
					touchRatio: 0.2,
					slideToClickedSlide: true
				},
			}
		},

		created() {

		},

		mounted() {
			this.$nextTick(() => {
				const swiperTop = this.$refs.swiperTop.swiper
				const swiperThumbs = this.$refs.swiperThumbs.swiper
				console.log(swiperTop)
				swiperTop.controller.control = swiperThumbs
				swiperThumbs.controller.control = swiperTop
			})
		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.developmentHistory{
			animation-fill-mode: both;
			background-size: cover;
			background-position: center center;
			padding: 60px 200px;
			
			.dh_title_cn{
				text-align: center;
				font-size: 32px;
				font-weight: bold;
				color: #fff;
			}
			
			.dh_title_en{
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				line-height: 1;
			}
		}
		
		.sl_textTime{
			text-align: center;
			font-weight: bold;
			color: #fff;
			font-size: 32px;
		}
		
		.thumb-example {
			height: 680px;
		}
		
		.swiper {
			position: relative;
			.swiper-slide {
				background-size: cover;
				background-position: center;
				
				.slide {
					position: relative;
					width: 100%;
					height: 100%;
				}
		
				// &.slide-1 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ffd98772-7609-4659-854b-beea5b2f5f5d.jpg?tenantId=252768&viewType=1&k=1678089441000');
				// }
		
				// &.slide-2 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=1');
				// }
		
				// &.slide-3 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=2');
				// }
		
				// &.slide-4 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=3');
				// }
		
				// &.slide-5 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=4');
				// }
			}
			
			.sl_div{
				display: flex;
				align-items: center;
				height: 100%;
				position: relative;
			}
			
			.sl_div_div{
				width: 100%;
				height: 30px;
				display: flex;
				align-items: center;
				position: relative;
			}
			
			.sl_div_div_circle{
				width: 30px;
				height: 100%;
				background: #fff;
				border-radius: 50%;
				transform: translate(-50%,-50%);
				position: absolute;
				top: 50%;
				left: 50%;
			}
			
			.sl_div_div_line{
				height: 2px;
				background: #fff;
				width: 100%;
			}
		
			&.gallery-top {
				height: 80%;
				width: 100%;
			}
			
			.slide2{
				background-size: cover;
				background-position: center;
				position: relative;
				
				.sl2_div{
					position: absolute;
					top: 0;
					left: 0;
					width: 50%;
					height: 100%;
					display: flex;
					align-items: center;
					
					.sl2_div_div{
						color: #333;
						z-index: 12;
						margin: 90px;
						
						.sl2_div_div_time{
							font-weight: bold;
							font-size: 32px;
							color: #1074bc;
						}
						
						.sl2_div_div_content{
							font-size: 24px;
							padding-top: 20px;
						}
					}
					
					.sl2_div_bg{
						background: #fff;
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						opacity: .5;
						z-index: 10;
					}
				}
			}
		
			&.gallery-thumbs {
				height: 20%;
				box-sizing: border-box;
				padding: 20px 0;
			}
		
			&.gallery-thumbs .swiper-slide {
				width: 25%;
				height: 100%;
				// opacity: 0.4;
				// border: 2px solid #1074bc;
			}
		
			&.gallery-thumbs .swiper-slide-active {
				opacity: 1;
			}
			
			&.gallery-thumbs .swiper-slide-active .sl_div_div_circle {
				border: 2px solid #1074bc;
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.developmentHistory{
			animation-fill-mode: both;
			background-size: cover;
			background-position: center center;
			padding: 60px 200px;
			
			.dh_title_cn{
				text-align: center;
				font-size: 32px;
				font-weight: bold;
				color: #fff;
			}
			
			.dh_title_en{
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				line-height: 1;
			}
		}
		
		.sl_textTime{
			text-align: center;
			font-weight: bold;
			color: #fff;
			font-size: 32px;
		}
		
		.thumb-example {
			height: 680px;
		}
		
		.swiper {
			position: relative;
			.swiper-slide {
				background-size: cover;
				background-position: center;
				
				.slide {
					position: relative;
					width: 100%;
					height: 100%;
				}
		
				// &.slide-1 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ffd98772-7609-4659-854b-beea5b2f5f5d.jpg?tenantId=252768&viewType=1&k=1678089441000');
				// }
		
				// &.slide-2 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=1');
				// }
		
				// &.slide-3 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=2');
				// }
		
				// &.slide-4 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=3');
				// }
		
				// &.slide-5 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=4');
				// }
			}
			
			.sl_div{
				display: flex;
				align-items: center;
				height: 100%;
				position: relative;
			}
			
			.sl_div_div{
				width: 100%;
				height: 30px;
				display: flex;
				align-items: center;
				position: relative;
			}
			
			.sl_div_div_circle{
				width: 30px;
				height: 100%;
				background: #fff;
				border-radius: 50%;
				transform: translate(-50%,-50%);
				position: absolute;
				top: 50%;
				left: 50%;
			}
			
			.sl_div_div_line{
				height: 2px;
				background: #fff;
				width: 100%;
			}
		
			&.gallery-top {
				height: 80%;
				width: 100%;
			}
			
			.slide2{
				background-size: cover;
				background-position: center;
				position: relative;
				
				.sl2_div{
					position: absolute;
					top: 0;
					left: 0;
					width: 50%;
					height: 100%;
					display: flex;
					align-items: center;
					
					.sl2_div_div{
						color: #333;
						z-index: 12;
						margin: 90px;
						
						.sl2_div_div_time{
							font-weight: bold;
							font-size: 32px;
							color: #1074bc;
						}
						
						.sl2_div_div_content{
							font-size: 24px;
							padding-top: 20px;
						}
					}
					
					.sl2_div_bg{
						background: #fff;
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						opacity: .5;
						z-index: 10;
					}
				}
			}
		
			&.gallery-thumbs {
				height: 20%;
				box-sizing: border-box;
				padding: 20px 0;
			}
		
			&.gallery-thumbs .swiper-slide {
				width: 25%;
				height: 100%;
				// opacity: 0.4;
				// border: 2px solid #1074bc;
			}
		
			&.gallery-thumbs .swiper-slide-active {
				opacity: 1;
			}
			
			&.gallery-thumbs .swiper-slide-active .sl_div_div_circle {
				border: 2px solid #1074bc;
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.developmentHistory{
			animation-fill-mode: both;
			background-size: cover;
			background-position: center center;
			padding: 60px 100px;
			
			.dh_title_cn{
				text-align: center;
				font-size: 32px;
				font-weight: bold;
				color: #fff;
			}
			
			.dh_title_en{
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				line-height: 1;
			}
		}
		
		.sl_textTime{
			text-align: center;
			font-weight: bold;
			color: #fff;
			font-size: 32px;
		}
		
		.thumb-example {
			height: 680px;
		}
		
		.swiper {
			position: relative;
			.swiper-slide {
				background-size: cover;
				background-position: center;
				
				.slide {
					position: relative;
					width: 100%;
					height: 100%;
				}
		
				// &.slide-1 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ffd98772-7609-4659-854b-beea5b2f5f5d.jpg?tenantId=252768&viewType=1&k=1678089441000');
				// }
		
				// &.slide-2 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=1');
				// }
		
				// &.slide-3 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=2');
				// }
		
				// &.slide-4 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=3');
				// }
		
				// &.slide-5 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=4');
				// }
			}
			
			.sl_div{
				display: flex;
				align-items: center;
				height: 100%;
				position: relative;
			}
			
			.sl_div_div{
				width: 100%;
				height: 30px;
				display: flex;
				align-items: center;
				position: relative;
			}
			
			.sl_div_div_circle{
				width: 30px;
				height: 100%;
				background: #fff;
				border-radius: 50%;
				transform: translate(-50%,-50%);
				position: absolute;
				top: 50%;
				left: 50%;
			}
			
			.sl_div_div_line{
				height: 2px;
				background: #fff;
				width: 100%;
			}
		
			&.gallery-top {
				height: 80%;
				width: 100%;
			}
			
			.slide2{
				background-size: cover;
				background-position: center;
				position: relative;
				
				.sl2_div{
					position: absolute;
					top: 0;
					left: 0;
					width: 50%;
					height: 100%;
					display: flex;
					align-items: center;
					
					.sl2_div_div{
						color: #333;
						z-index: 12;
						margin: 90px;
						
						.sl2_div_div_time{
							font-weight: bold;
							font-size: 32px;
							color: #1074bc;
						}
						
						.sl2_div_div_content{
							font-size: 24px;
							padding-top: 20px;
						}
					}
					
					.sl2_div_bg{
						background: #fff;
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						opacity: .5;
						z-index: 10;
					}
				}
			}
		
			&.gallery-thumbs {
				height: 20%;
				box-sizing: border-box;
				padding: 20px 0;
			}
		
			&.gallery-thumbs .swiper-slide {
				width: 25%;
				height: 100%;
				// opacity: 0.4;
				// border: 2px solid #1074bc;
			}
		
			&.gallery-thumbs .swiper-slide-active {
				opacity: 1;
			}
			
			&.gallery-thumbs .swiper-slide-active .sl_div_div_circle {
				border: 2px solid #1074bc;
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.developmentHistory{
			animation-fill-mode: both;
			background-size: cover;
			background-position: center center;
			padding: 60px 60px;
			
			.dh_title_cn{
				text-align: center;
				font-size: 32px;
				font-weight: bold;
				color: #fff;
			}
			
			.dh_title_en{
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				line-height: 1;
			}
		}
		
		.sl_textTime{
			text-align: center;
			font-weight: bold;
			color: #fff;
			font-size: 20px;
		}
		
		.thumb-example {
			height: 680px;
		}
		
		.swiper {
			position: relative;
			.swiper-slide {
				background-size: cover;
				background-position: center;
				
				.slide {
					position: relative;
					width: 100%;
					height: 100%;
				}
		
				// &.slide-1 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ffd98772-7609-4659-854b-beea5b2f5f5d.jpg?tenantId=252768&viewType=1&k=1678089441000');
				// }
		
				// &.slide-2 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=1');
				// }
		
				// &.slide-3 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=2');
				// }
		
				// &.slide-4 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=3');
				// }
		
				// &.slide-5 {
				// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=4');
				// }
			}
			
			.sl_div{
				display: flex;
				align-items: center;
				height: 100%;
				position: relative;
			}
			
			.sl_div_div{
				width: 100%;
				height: 30px;
				display: flex;
				align-items: center;
				position: relative;
			}
			
			.sl_div_div_circle{
				width: 30px;
				height: 100%;
				background: #fff;
				border-radius: 50%;
				transform: translate(-50%,-50%);
				position: absolute;
				top: 50%;
				left: 50%;
			}
			
			.sl_div_div_line{
				height: 2px;
				background: #fff;
				width: 100%;
			}
		
			&.gallery-top {
				height: 80%;
				width: 100%;
			}
			
			.slide2{
				background-size: cover;
				background-position: center;
				position: relative;
				
				.sl2_div{
					position: absolute;
					top: 0;
					left: 0;
					width: 50%;
					height: 100%;
					display: flex;
					align-items: center;
					
					.sl2_div_div{
						color: #333;
						z-index: 12;
						margin: 90px;
						
						.sl2_div_div_time{
							font-weight: bold;
							font-size: 32px;
							color: #1074bc;
						}
						
						.sl2_div_div_content{
							font-size: 24px;
							padding-top: 20px;
						}
					}
					
					.sl2_div_bg{
						background: #fff;
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						opacity: .5;
						z-index: 10;
					}
				}
			}
		
			&.gallery-thumbs {
				height: 20%;
				box-sizing: border-box;
				padding: 20px 0;
			}
		
			&.gallery-thumbs .swiper-slide {
				width: 25%;
				height: 100%;
				// opacity: 0.4;
				// border: 2px solid #1074bc;
			}
		
			&.gallery-thumbs .swiper-slide-active {
				opacity: 1;
			}
			
			&.gallery-thumbs .swiper-slide-active .sl_div_div_circle {
				border: 2px solid #1074bc;
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		
			.developmentHistory{
				animation-fill-mode: both;
				background-size: cover;
				background-position: center center;
				padding: 60px 60px;
				
				.dh_title_cn{
					text-align: center;
					font-size: 32px;
					font-weight: bold;
					color: #fff;
				}
				
				.dh_title_en{
					text-align: center;
					font-size: 24px;
					font-weight: bold;
					color: #fff;
					line-height: 1;
				}
			}
			
			.sl_textTime{
				text-align: center;
				font-weight: bold;
				color: #fff;
				font-size: 16px;
			}
			
			.thumb-example {
				height: 680px;
			}
			
			.swiper {
				position: relative;
				.swiper-slide {
					background-size: cover;
					background-position: center;
					
					.slide {
						position: relative;
						width: 100%;
						height: 100%;
					}
			
					// &.slide-1 {
					// 	background-image: url('http://img601.yun300.cn/repository/image/ffd98772-7609-4659-854b-beea5b2f5f5d.jpg?tenantId=252768&viewType=1&k=1678089441000');
					// }
			
					// &.slide-2 {
					// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=1');
					// }
			
					// &.slide-3 {
					// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=2');
					// }
			
					// &.slide-4 {
					// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=3');
					// }
			
					// &.slide-5 {
					// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=4');
					// }
				}
				
				.sl_div{
					display: flex;
					align-items: center;
					height: 100%;
					position: relative;
				}
				
				.sl_div_div{
					width: 100%;
					height: 30px;
					display: flex;
					align-items: center;
					position: relative;
				}
				
				.sl_div_div_circle{
					width: 30px;
					height: 100%;
					background: #fff;
					border-radius: 50%;
					transform: translate(-50%,-50%);
					position: absolute;
					top: 50%;
					left: 50%;
				}
				
				.sl_div_div_line{
					height: 2px;
					background: #fff;
					width: 100%;
				}
			
				&.gallery-top {
					height: 80%;
					width: 100%;
				}
				
				.slide2{
					background-size: cover;
					background-position: center;
					position: relative;
					
					.sl2_div{
						position: absolute;
						top: 0;
						left: 0;
						width: 70%;
						height: 100%;
						display: flex;
						align-items: center;
						
						.sl2_div_div{
							color: #333;
							z-index: 12;
							margin: 90px;
							
							.sl2_div_div_time{
								font-weight: bold;
								font-size: 26px;
								color: #1074bc;
							}
							
							.sl2_div_div_content{
								font-size: 16px;
								padding-top: 20px;
							}
						}
						
						.sl2_div_bg{
							background: #fff;
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
							opacity: .5;
							z-index: 10;
						}
					}
				}
			
				&.gallery-thumbs {
					height: 20%;
					box-sizing: border-box;
					padding: 20px 0;
				}
			
				&.gallery-thumbs .swiper-slide {
					width: 25%;
					height: 100%;
					// opacity: 0.4;
					// border: 2px solid #1074bc;
				}
			
				&.gallery-thumbs .swiper-slide-active {
					opacity: 1;
				}
				
				&.gallery-thumbs .swiper-slide-active .sl_div_div_circle {
					border: 2px solid #1074bc;
				}
			}
		
	}
	
	@media only screen and (max-width:767px) {
		
			
				.developmentHistory{
					animation-fill-mode: both;
					background-size: cover;
					background-position: center center;
					padding: 20px;
					
					.dh_title_cn{
						text-align: center;
						font-size: 32px;
						font-weight: bold;
						color: #fff;
					}
					
					.dh_title_en{
						text-align: center;
						font-size: 24px;
						font-weight: bold;
						color: #fff;
						line-height: 1;
					}
				}
				
				.sl_textTime{
					text-align: center;
					font-weight: bold;
					color: #fff;
					font-size:10px;
					height: 36px;
				}
				
				.thumb-example {
					height: 680px;
				}
				
				.swiper {
					position: relative;
					.swiper-slide {
						background-size: cover;
						background-position: center;
						
						.slide {
							position: relative;
							width: 100%;
							height: 100%;
						}
				
						// &.slide-1 {
						// 	background-image: url('http://img601.yun300.cn/repository/image/ffd98772-7609-4659-854b-beea5b2f5f5d.jpg?tenantId=252768&viewType=1&k=1678089441000');
						// }
				
						// &.slide-2 {
						// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=1');
						// }
				
						// &.slide-3 {
						// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=2');
						// }
				
						// &.slide-4 {
						// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=3');
						// }
				
						// &.slide-5 {
						// 	background-image: url('http://img601.yun300.cn/repository/image/ecff4280-4998-4779-aee0-e91b0d9482fe.jpg?tenantId=252768&viewType=4');
						// }
					}
					
					.sl_div{
						display: flex;
						align-items: center;
						height: 100%;
						position: relative;
					}
					
					.sl_div_div{
						width: 100%;
						height: 30px;
						display: flex;
						align-items: center;
						position: relative;
					}
					
					.sl_div_div_circle{
						width: 30px;
						height: 100%;
						background: #fff;
						border-radius: 50%;
						transform: translate(-50%,-50%);
						position: absolute;
						top: 50%;
						left: 50%;
					}
					
					.sl_div_div_line{
						height: 2px;
						background: #fff;
						width: 100%;
					}
				
					&.gallery-top {
						height: 80%;
						width: 100%;
					}
					
					.slide2{
						background-size: cover;
						background-position: center;
						position: relative;
						
						.sl2_div{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							
							.sl2_div_div{
								color: #333;
								z-index: 12;
								margin: 90px;
								
								.sl2_div_div_time{
									font-weight: bold;
									font-size: 26px;
									color: #1074bc;
								}
								
								.sl2_div_div_content{
									font-size: 16px;
									padding-top: 20px;
								}
							}
							
							.sl2_div_bg{
								background: #fff;
								position: absolute;
								left: 0;
								top: 0;
								width: 100%;
								height: 100%;
								opacity: .5;
								z-index: 10;
							}
						}
					}
				
					&.gallery-thumbs {
						height: 20%;
						box-sizing: border-box;
						padding: 20px 0;
					}
				
					&.gallery-thumbs .swiper-slide {
						width: 25%;
						height: 100%;
						// opacity: 0.4;
						// border: 2px solid #1074bc;
					}
				
					&.gallery-thumbs .swiper-slide-active {
						opacity: 1;
					}
					
					&.gallery-thumbs .swiper-slide-active .sl_div_div_circle {
						border: 2px solid #1074bc;
					}
				}
			
		
	}
</style>
