import { render, staticRenderFns } from "./developmentHistory.vue?vue&type=template&id=0a45ad49&scoped=true&"
import script from "./developmentHistory.vue?vue&type=script&lang=js&"
export * from "./developmentHistory.vue?vue&type=script&lang=js&"
import style0 from "./developmentHistory.vue?vue&type=style&index=0&id=0a45ad49&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a45ad49",
  null
  
)

export default component.exports